import styles from './TimeLogEntries.module.scss';
import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FirestoreError, Timestamp, doc, onSnapshot } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { db, auth } from '../../../firebase-config';
import { TimeModalContext } from '../../../contexts/TimeModalContext';
import { UserContext } from '../../../contexts/UserContext';
import { TimeLogInterface } from '../../../types/Time';
import TimeLogSummary from './TimeLogSummary';
import TimeLogEntry from './TimeLogEntry';
import Panel from '../../panel/Panel';
import Button from '../../button/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
import { isDateLocked } from '../../../utils/isDateLocked';
import TimeLogHeader from './TimeLogHeader';
import TimeLogEntryWorkHours from './TimeLogEntryWorkHours';
import TimeLogInformationPanel from './TimeLogInformationPanel';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';
import TimeHistoryModal from '../historymodal/TimeHistoryModal';

function TimeLogEntries() {
  const { user, userInfo, company } = useContext(UserContext);
  const { date = new Date().toISOString().split('T')[0] } = useParams() as {
    date: string;
  };
  const [logModalOpen, setLogModalOpen] = useState(false);

  const { open } = useContext(TimeModalContext);
  const dateObj = new Date(date);
  const [timeLog, setTimeLog] = useState<TimeLogInterface | null>(null);
  const companyStartDate = getCompanyStartDate(company);

  const isLocked = isDateLocked(
    dateObj,
    userInfo.timeLogApprovedWeeks,
    userInfo.startDate,
    companyStartDate
  );
  const warnings = userInfo?.timeLogWarnings
    ?.filter(
      (warning) =>
        warning.fromDate.toDate().getTime() <= dateObj.getTime() &&
        warning.toDate.toDate().getTime() >= dateObj.getTime()
    )
    ?.sort(
      (a, b) => a.fromDate.toDate().getTime() - b.fromDate.toDate().getTime()
    );

  // Get the time log setting for the selected date
  const timeLogSetting = currentTimeLogSetting(company, userInfo, dateObj);

  // Open the modal to create a new time log entry
  const handleOpen = () => {
    if (isLocked) return;

    const startTimeWithNormHours = new Date(dateObj);
    const endTimeWithnormHours = new Date(dateObj);

    if (timeLogSetting?.defaultType === 'overtime') {
      // Set the start time to the end of the day
      startTimeWithNormHours.setHours(
        timeLogSetting?.end.toDate().getHours() || 0,
        timeLogSetting?.end.toDate().getMinutes() || 0
      );

      // Set the end time to the end of the day plus 1 hour
      endTimeWithnormHours.setHours(startTimeWithNormHours.getHours() + 1, 0);
    } else {
      startTimeWithNormHours.setHours(
        timeLogSetting?.from.toDate().getHours() || 0,
        timeLogSetting?.from.toDate().getMinutes() || 0
      );

      endTimeWithnormHours.setHours(
        timeLogSetting?.end.toDate().getHours() || 0,
        timeLogSetting?.end.toDate().getMinutes() || 0
      );
    }

    const breakDuration = timeLogSetting?.breakDuration ?? 0;

    const calculatedHours = !timeLogSetting?.paidBreak
      ? Math.abs(
          endTimeWithnormHours.getTime() -
            startTimeWithNormHours.getTime() -
            // e.g. 30 minutes to milliseconds
            breakDuration * 60 * 1000
        )
      : Math.abs(
          endTimeWithnormHours.getTime() - startTimeWithNormHours.getTime()
        );

    open(timeLog || undefined, {
      startTime: Timestamp.fromDate(startTimeWithNormHours),
      endTime: Timestamp.fromDate(endTimeWithnormHours),
      hours: calculatedHours / 36e5,
      type: timeLogSetting?.defaultType,
      timeOffType: '',
      breakWasIncluded: !timeLogSetting?.paidBreak,
    });
  };

  // Get and listen to changes on the time log document for the selected date
  useEffect(() => {
    const timeLogDocRef = doc(db, 'users', user.uid, 'logs', date);

    const unsubscribe = onSnapshot(
      timeLogDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setTimeLog({
            id: docSnapshot.id,
            data: docSnapshot.data(),
          } as TimeLogInterface);
        }
      },
      (error: FirestoreError) => {
        console.log('Error listening to document:', error);
        if (error.code === 'permission-denied') {
          // User does not have permission to access the document, sign them out
          signOut(auth);

          // Then redirect the user to the login page
          window.location.href = '/';
        }
        console.error('Error listening to document:', error);
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [user, date]);

  // Sorted based on time of day, e.g. 08:00 - 16:00
  const sortedEntries =
    timeLog?.data?.entries.sort(
      (a, b) => a.startTime.toMillis() - b.startTime.toMillis()
    ) || [];

  // Sorted based on time of day, e.g. 08:00 - 16:00
  const sortedLogs =
    timeLog?.data?.logs?.sort(
      (a, b) => a.timestamp.toMillis() - b.timestamp.toMillis()
    ) || [];

  // Define lockDate as the later of userStartDate and companyStartDate
  let lockDate: Date | undefined;
  if (userInfo.startDate && companyStartDate) {
    const userStart = userInfo.startDate.toDate();
    lockDate = userStart > companyStartDate ? userStart : companyStartDate;
  } else if (companyStartDate) {
    lockDate = companyStartDate;
  } else if (userInfo.startDate) {
    lockDate = userInfo.startDate.toDate();
  }

  const specialLockedDate = new Date('2024-08-05');

  // Determine if the date is locked because of start dates
  const isLockedByStartDate =
    lockDate && dateObj < lockDate && dateObj >= specialLockedDate;

  // If the user's start date is before the selected date, display a message
  if (isLockedByStartDate) {
    return (
      <>
        <TimeLogHeader />
        <TimeLogInformationPanel
          type="success"
          info={{
            title: 'Din startdato er efter denne dag',
            content:
              'Du kan ikke redigere registreringer for denne dag, da din startdato er efter denne dag.',
          }}
        />
      </>
    );
  }

  return (
    <>
      <TimeLogHeader />
      {isLocked && (
        <TimeLogInformationPanel
          type="success"
          info={{
            title: 'Denne dag er godkendt og låst',
            content:
              'Du kan ikke redigere registreringer for denne dag, da den er godkendt og låst.',
          }}
        />
      )}
      {warnings &&
        warnings.length > 0 &&
        warnings.map((warning) => {
          return (
            <TimeLogInformationPanel
              type="warning"
              key={warning.id}
              warning={warning}
            />
          );
        })}
      {timeLogSetting ? (
        <>
          <div className={styles.timeLogEntries}>
            <div className={styles.timeLogEntriesHeader}>
              <span className={styles.timeLogEntriesHeaderTitle}>
                Registreringer
              </span>
              {!isLocked && sortedEntries && sortedEntries?.length > 0 && (
                <Button
                  onClick={handleOpen}
                  buttonStyle="rounded-green"
                  size="tiny"
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 5C10 5.39449 9.6802 5.71429 9.28572 5.71429L5.71429 5.71429L5.71429 9.28572C5.71429 9.6802 5.39449 10 5 10C4.60551 10 4.28571 9.6802 4.28571 9.28572L4.28571 5.71429H0.714285C0.319796 5.71429 0 5.39449 0 5C0 4.60551 0.319797 4.28571 0.714286 4.28571H4.28571V0.714285C4.28571 0.319796 4.60551 0 5 0C5.39449 0 5.71429 0.319797 5.71429 0.714286V4.28571L9.28572 4.28571C9.6802 4.28571 10 4.60551 10 5Z"
                      fill="white"
                    />
                  </svg>
                  Opret
                </Button>
              )}
            </div>

            <AnimatePresence>
              <motion.div
                key={sortedEntries?.length}
                variants={{
                  visible: {
                    transition: {
                      delayChildren: 0.3,
                      staggerChildren: 0.1,
                    },
                  },
                  hidden: {
                    transition: { staggerChildren: 0, staggerDirection: -1 },
                  },
                }}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                {!timeLogSetting.isPaidHourly && (
                  <TimeLogEntryWorkHours
                    date={dateObj}
                    timeLogSetting={timeLogSetting}
                  />
                )}

                {sortedEntries?.map((entry, index) => (
                  <TimeLogEntry
                    key={index}
                    timeLog={timeLog as TimeLogInterface}
                    entry={entry}
                  />
                ))}

                {/* Display a message if there are no entries and the day is not locked */}
                {sortedEntries?.length === 0 && !isLocked && (
                  <motion.div
                    key={'empty'}
                    variants={{
                      hidden: {
                        opacity: 0,
                        y: 30,
                      },
                      visible: {
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: 0.1,
                        },
                      },
                    }}
                  >
                    <Panel size="small">
                      <div className={styles.timeLogNoEntries}>
                        <h5>
                          Her kan du registrere sygdom, ferie, arbejde uden for
                          normtid, fri eller andre afvigelser.
                        </h5>
                        <p>
                          Systemet registrerer automatisk resten af din dag som
                          arbejdstid, hvis der er en planlagt arbejdstid.
                        </p>
                        <Button
                          onClick={handleOpen}
                          buttonStyle="rounded-green"
                          size="small"
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 5C10 5.39449 9.6802 5.71429 9.28572 5.71429L5.71429 5.71429L5.71429 9.28572C5.71429 9.6802 5.39449 10 5 10C4.60551 10 4.28571 9.6802 4.28571 9.28572L4.28571 5.71429H0.714285C0.319796 5.71429 0 5.39449 0 5C0 4.60551 0.319797 4.28571 0.714286 4.28571H4.28571V0.714285C4.28571 0.319796 4.60551 0 5 0C5.39449 0 5.71429 0.319797 5.71429 0.714286V4.28571L9.28572 4.28571C9.6802 4.28571 10 4.60551 10 5Z"
                              fill="white"
                            />
                          </svg>
                          Opret registrering
                        </Button>
                      </div>
                    </Panel>
                  </motion.div>
                )}

                {sortedLogs?.length > 0 && (
                  <div style={{ marginTop: '2rem' }}>
                    <Button
                      buttonStyle="text-link--grey"
                      wide
                      onClick={() => setLogModalOpen(true)}
                    >
                      Se historik ({sortedLogs?.length})
                    </Button>
                    <TimeHistoryModal
                      isModalOpen={logModalOpen}
                      setIsModalOpen={setLogModalOpen}
                      logs={sortedLogs}
                    />
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
          <TimeLogSummary
            timeLog={timeLog as TimeLogInterface}
            timeLogSetting={timeLogSetting}
          />
        </>
      ) : (
        <div className={styles.timeLogNoSetting}>
          <Panel size="small">
            <div className={styles.timeLogNoEntries}>
              <h5>Der er ingen planlagt arbejdstid for denne dag.</h5>
            </div>
          </Panel>
        </div>
      )}
    </>
  );
}

export default TimeLogEntries;
