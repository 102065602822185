import { Timestamp } from 'firebase/firestore';
import DANISH_HOLIDAYS from '../constants/danishHolidays';
import { CompanyContextInterface } from '../types/Company';
import {
  TimeLogEntryType,
  TimeLogInterface,
  weeklyScheduleItem,
} from '../types/Time';
import { UserInfoContextInterface } from '../types/User';
import { currentTimeLogSetting } from './currentTimeLogSetting';

function calculateWorkingHours(
  date: Date,
  timeLogSetting: {
    breakDuration: number;
    paidBreak: boolean;
    from: Timestamp;
    end: Timestamp;
    notWorking: boolean;
    defaultType?: string;
    isPaidHourly: boolean;
    weeklySchedule: weeklyScheduleItem[];
  }
) {
  const { weeklySchedule, paidBreak, breakDuration } = timeLogSetting;

  let totalHours = 0;
  const dayName = date.toLocaleString('en-US', { weekday: 'long' }); // Get day name

  const dailySchedule = weeklySchedule.find((d) => d.day === dayName);
  if (dailySchedule && !dailySchedule.notWorking) {
    const fromHours = dailySchedule.from.toDate().getHours();
    const fromMinutes = dailySchedule.from.toDate().getMinutes();
    const endHours = dailySchedule.end.toDate().getHours();
    const endMinutes = dailySchedule.end.toDate().getMinutes();

    let workDuration =
      endHours + endMinutes / 60 - (fromHours + fromMinutes / 60);

    // If the work duration is negative, set it to 0
    if (workDuration < 0) {
      workDuration = 0;
    }

    // Subtract the break time if unpaid
    if (!paidBreak) {
      const breakHours = breakDuration / 60;
      workDuration -= breakHours;

      // Ensure workDuration is non-negative
      if (workDuration < 0) {
        workDuration = 0;
      }
    }

    // Normalize to prevent floating-point anomalies
    workDuration = Math.round(workDuration * 2) / 2; // Round to nearest 0.5

    totalHours += workDuration;
  }

  return totalHours;
}

export const calculateSummaryNumbers = (
  logs: TimeLogInterface[],
  startDate: Date,
  endDate: Date,
  company: CompanyContextInterface,
  userInfo: UserInfoContextInterface
) => {
  // Go through all dates in the period and calculate all the numbers, taking the logs into account
  let total = 0;
  let normTime = 0;
  let overtime = 0;
  let overtimeCount = 0;
  let timeOff = 0;
  let timeOffCount = 0;
  let holiday = 0;
  let holidayCount = 0;
  let sick = 0;
  let sickCount = 0;
  let work = 0;
  let workCount = 0;

  const adjustedStartDate = new Date(
    Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    )
  );
  const adjustedEndDate = new Date(
    Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59,
      999
    )
  );

  // Go through each day in the period to calculate the norm time
  Array.from(
    {
      length:
        (adjustedEndDate.getTime() - adjustedStartDate.getTime()) / 86400000 +
        1,
    },
    (_, i) => new Date(adjustedStartDate.getTime() + i * 86400000)
  ).forEach((date) => {
    const utcDate = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );

    const timeLogSetting = currentTimeLogSetting(company, userInfo, utcDate);
    if (!timeLogSetting) return;

    const isHoliday = DANISH_HOLIDAYS.some(
      (holiday) => holiday.value === utcDate.toISOString().split('T')[0]
    );

    if (timeLogSetting.notWorking || isHoliday || utcDate > new Date()) {
      return;
    }

    normTime += calculateWorkingHours(utcDate, timeLogSetting);
  });

  // Go through each log to calculate the numbers
  logs.forEach((log) => {
    const timeLogSetting = currentTimeLogSetting(
      company,
      userInfo,
      log.data.date.toDate()
    );

    if (!timeLogSetting) return;

    log.data.entries.forEach((entry: TimeLogEntryType) => {
      if (entry.type === 'overtime') {
        overtime += entry.hours;
        overtimeCount++;
      } else if (entry.type === 'timeOff') {
        timeOff += entry.hours;
        timeOffCount++;
      } else if (entry.type === 'holiday') {
        holiday += entry.hours;
        holidayCount++;
      } else if (entry.type === 'sickLeave') {
        sick += entry.hours;
        sickCount++;
      } else if (entry.type === 'work') {
        work += entry.hours;
        workCount++;
      }
    });
  });

  work += normTime - timeOff - holiday - sick;

  // Calculate the total work time based on the numbers
  total = work + overtime;

  return {
    normTime,
    total,
    overtime: {
      label: 'Arbejde u.f. normtid',
      count: overtimeCount,
      hours: overtime,
    },
    timeOff: {
      label: 'Fri',
      count: timeOffCount,
      hours: timeOff,
    },
    holiday: {
      label: 'Ferie',
      count: holidayCount,
      hours: holiday,
    },
    sick: {
      label: 'Syg',
      count: sickCount,
      hours: sick,
    },
    work: {
      label: 'Arbejdstid',
      count: workCount,
      hours: work,
    },
  };
};
